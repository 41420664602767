import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';

const BASE = '/working-unit';

export const getWorkingUnits = async ({ location, hasMatchingOfferedCluster } = {}) => {
    let url = `${BASE}`;
    url = appendQueryParamsToUrl(url, { location, hasMatchingOfferedCluster });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const createWorkingUnit = async (data) => {
    return api.post(`${BASE}`, { data }, await setToken()).then((res) => res.data);
};

export const updateWorkingUnit = async (data) => {
    const { id } = data;
    return api.patch(`${BASE}/${id}`, { data }, await setToken()).then((res) => res.data);
};
