import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import ReactHookFormSelect from '../ReactHookForm/ReactHookFormSelect';
import { fieldIsRequiredMsg } from '../../utils/componentUtils';
import FormDialog from '../Dialogs/FormDialog';
import ReactHookFormCheckbox from '../ReactHookForm/ReactHookFormCheckbox';

const WorkingUnitDialog = ({
    workingUnit,
    showDialog,
    channelOptions,
    taskOptions,
    worktypeOptions,
    onSubmit,
    isLoading,
    onClose,
}) => {
    const form = useForm({
        defaultValues: {
            id: workingUnit?.id || '',
            worktype: workingUnit?.worktypeId || '',
            channel: workingUnit?.channelId || '',
            task: workingUnit?.taskId || '',
            matchWithOfferedCluster: workingUnit?.id ? !!workingUnit?.offeredClusterId : true,
        },
    });
    const {
        formState: { errors },
        control,
    } = form;

    const handleClose = () => {
        onClose();
    };

    return (
        <FormDialog
            title={workingUnit?.id ? 'Bearbeiten der Arbeitseinheit' : 'Neue Arbeitseinheit erstellen'}
            showDialog={showDialog}
            form={form}
            onSubmit={onSubmit}
            onClose={handleClose}
            isLoading={isLoading}
        >
            <Grid item xs={12}>
                <ReactHookFormSelect
                    name="worktype"
                    options={worktypeOptions}
                    mainProp="worktype"
                    label="Worktype"
                    control={control}
                    disabled={!!workingUnit?.id}
                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                    formErrors={errors}
                ></ReactHookFormSelect>
            </Grid>
            <Grid item xs={12}>
                <ReactHookFormSelect
                    name="channel"
                    options={channelOptions}
                    mainProp="name"
                    label="Channel"
                    control={control}
                    disabled={!!workingUnit?.id}
                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                    formErrors={errors}
                ></ReactHookFormSelect>
            </Grid>
            <Grid item xs={12}>
                <ReactHookFormSelect
                    name="task"
                    options={taskOptions}
                    mainProp="name"
                    label="Task"
                    control={control}
                    disabled={!!workingUnit?.id}
                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                    formErrors={errors}
                ></ReactHookFormSelect>
            </Grid>
            <Grid item xs={12}>
                <ReactHookFormCheckbox
                    name="matchWithOfferedCluster"
                    control={control}
                    label="Match with Offered Cluster"
                    formErrors={errors}
                ></ReactHookFormCheckbox>
            </Grid>
        </FormDialog>
    );
};

export default WorkingUnitDialog;
