import api from './index';
import { setToken } from '../utils/authUtils';
import { hemtApiURL } from '../config';

export const getAllLocationFromHEMT = async () => {
    return api
        .get(`${hemtApiURL}/location`, await setToken())
        .then((res) => res.data)
        .catch((error) => error.message);
};

export const getUserById = async (userId) => {
    return api
        .get(`${hemtApiURL}/user/${userId}`, await setToken())
        .then((res) => res.data)
        .catch((error) => error.message);
};

export const getAllUsersFromHEMT = async () => {
    return api
        .get(`${hemtApiURL}/user`, await setToken())
        .then((res) => res.data)
        .catch((error) => error.message);
};
