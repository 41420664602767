export const envName = process.env.REACT_APP_ENV_NAME || process.env.NODE_ENV || 'development';
export const isProduction = envName === 'production';
export const isDevelopment = envName === 'development';
export const hemtURL = process.env.REACT_APP_HEMT_URL || 'https://telefonica-hemt.unetiq.com';
export const hemtApiURL = process.env.REACT_APP_HEMT_API_URL || 'https://telefonica-hemt.unetiq.com/api';
export const appURL = process.env.REACT_APP_URL || 'http://localhost:3000';
export const apiURL = process.env.REACT_APP_API_URL || 'http://localhost:5050/api';
export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
export const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE || '';
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN || '';
export const agGridLicense = process.env.REACT_APP_AG_GRID_LICENSE || '';

if (!apiURL || !apiURL.startsWith('http')) {
    throw Error('Environment variable `REACT_APP_API_URL` needs to start with `http` or `https`');
}

const config = {
    envName,
    isProduction,
    isDevelopment,
    appURL,
    apiURL,
    hemtURL,
    hemtApiURL,
    auth0Domain,
    auth0ClientId,
    auth0Audience,
    sentryDsn,
    agGridLicense,
};

export default config;
