import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ReactHookFormSelect from '../ReactHookForm/ReactHookFormSelect';
import { useHEMTLocations } from '../../hooks';
import { fieldIsRequiredMsg } from '../../utils/componentUtils';
import { useWorktypes } from '../../hooks';
import FormDialog from '../Dialogs/FormDialog';

const CreateCapacityDialog = ({
    showDialog,
    channelOptions,
    taskOptions,
    capacityTypeOptions,
    onSubmit,
    isLoading,
    onClose,
}) => {
    const allLocationsFromHEMT = useHEMTLocations();

    const form = useForm({
        defaultValues: {
            location: '',
            worktype: '',
            channel: '',
            task: '',
            capacityTypes: [],
        },
    });
    const {
        watch,
        formState: { errors },
        resetField,
        control,
    } = form;
    const selectedLocation = watch('location');

    const [worktypeOptions] = useWorktypes({
        location: selectedLocation,
    });

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (selectedLocation) {
            resetField('worktype');
        }
    }, [selectedLocation, resetField]);

    return (
        <FormDialog
            title="Neue Capacities anlegen"
            showDialog={showDialog}
            form={form}
            onSubmit={onSubmit}
            onClose={handleClose}
            isLoading={isLoading}
        >
            <Grid item xs={12}>
                <ReactHookFormSelect
                    name="location"
                    options={allLocationsFromHEMT}
                    mainProp="code"
                    secondProp="city"
                    label="Standort"
                    control={control}
                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                    formErrors={errors}
                ></ReactHookFormSelect>
            </Grid>
            <Grid item xs={12}>
                <ReactHookFormSelect
                    name="worktype"
                    options={worktypeOptions}
                    mainProp="worktype"
                    label="Worktype"
                    control={control}
                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                    formErrors={errors}
                ></ReactHookFormSelect>
            </Grid>
            <Grid item xs={12}>
                <ReactHookFormSelect
                    name="channel"
                    options={channelOptions}
                    mainProp="name"
                    label="Channel"
                    control={control}
                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                    formErrors={errors}
                ></ReactHookFormSelect>
            </Grid>
            <Grid item xs={12}>
                <ReactHookFormSelect
                    name="task"
                    options={taskOptions}
                    mainProp="name"
                    label="Task"
                    control={control}
                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                    formErrors={errors}
                ></ReactHookFormSelect>
            </Grid>
            <Grid item xs={12}>
                <ReactHookFormSelect
                    name="capacityTypes"
                    options={capacityTypeOptions}
                    mainProp="name"
                    label="Capacity Types"
                    control={control}
                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                    formErrors={errors}
                    isMultiSelect={true}
                ></ReactHookFormSelect>
            </Grid>
        </FormDialog>
    );
};

export default CreateCapacityDialog;
