import { useState, useCallback } from 'react';
import CRUDSettings from '../components/General/CRUDSettings';
import {
    useChannels,
    useTasks,
    useWorktypes,
    useCreateWorkingUnit,
    useUpdateWorkingUnit,
    useWorkingUnits,
} from '../hooks';
import { getCellValue } from '../utils/agGridUtils';
import WorkingUnitDialog from '../components/WorkingUnits/WorkingUnitDialog';
import { Checkbox, FormControlLabel } from '@mui/material';

const WorkingUnits = () => {
    const [worktypes] = useWorktypes();
    const [channels] = useChannels();
    const [tasks] = useTasks();
    const [selectedWorkingUnit, setSelectedWorkingUnit] = useState(null);
    const [hasMatchingOfferedCluster, setHasMatchingOfferedCluster] = useState(true);

    const [workingUnits, isWorkingUnitsLoading] = useWorkingUnits({
        hasMatchingOfferedCluster,
    });

    const [showCreateWorkingUnitDialog, setShowCreateWorkingUnitDialog] = useState(false);
    const { mutate: handleCreateWorkingUnit, isLoading: isWorkingUnitCreating } = useCreateWorkingUnit({
        onSettled: (data, error) => {
            if (!error) setShowCreateWorkingUnitDialog(false);
        },
    });
    const { mutate: handleUpdateWorkingUnit, isLoading: isWorkingUnitUpdating } = useUpdateWorkingUnit({
        onSettled: (data, error) => {
            if (!error) {
                setShowCreateWorkingUnitDialog(false);
                setSelectedWorkingUnit(null);
            }
        },
    });

    const handleFormSubmit = useCallback(
        ({ id, worktype, channel, task, matchWithOfferedCluster }) => {
            const workingUnit = {
                ...(id && { id }),
                worktype,
                channel,
                task,
                matchWithOfferedCluster,
            };

            if (id) {
                handleUpdateWorkingUnit(workingUnit);
                return;
            }

            handleCreateWorkingUnit(workingUnit);
        },
        [handleCreateWorkingUnit, handleUpdateWorkingUnit]
    );

    const openCreateDialog = (value) => {
        setSelectedWorkingUnit(null);
        setShowCreateWorkingUnitDialog(value);
    };

    const getCommonColDefs = (values, colName, secondColName) => {
        return {
            editable: (params) => params.data.isRowEdited || params.data.isNewRecord,
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: (params) => ({
                values: Array.isArray(values) ? values : values(params),
                cellRenderer: (params) => getCellValue(params, colName, secondColName),
            }),
            cellRenderer: (params) => getCellValue(params, colName, secondColName),
        };
    };

    const columnDefs = [
        {
            field: 'worktype',
            headerName: 'Worktype',
            ...getCommonColDefs(worktypes, 'worktype'),
            filterParams: {
                valueGetter: ({ data }) => {
                    return data?.worktype?.worktype;
                },
            },
            valueFormatter: ({ value }) => value?.worktype,
        },
        {
            field: 'channel',
            headerName: 'Kanal',
            ...getCommonColDefs(channels, 'name'),
            filterParams: {
                valueGetter: ({ data }) => {
                    return data?.channel?.name;
                },
            },
            valueFormatter: ({ value }) => value?.name,
        },
        {
            field: 'task',
            headerName: 'Task',
            ...getCommonColDefs(tasks, 'name'),
            filterParams: {
                valueGetter: ({ data }) => {
                    return data?.task?.name;
                },
            },
            valueFormatter: ({ value }) => value?.name,
        },
        {
            field: 'offeredClusterId',
            headerName: 'Offered Cluster',
            valueGetter: ({ data }) => (data.offeredClusterId ? 'Yes' : 'No'),
        },
    ];

    const filters = (
        <FormControlLabel
            control={
                <Checkbox
                    name="match"
                    checked={hasMatchingOfferedCluster}
                    onChange={(e) => setHasMatchingOfferedCluster(e.target.checked)}
                />
            }
            label="Match with Offered Cluster"
        />
    );

    return (
        <>
            <CRUDSettings
                tableRows={workingUnits}
                columnDefs={columnDefs}
                columnToFocusOnEdit="location"
                btnText="Neuer Working Unit"
                recordType="Working Unit"
                pagination={true}
                paginationPageSize={20}
                paginationPageSizeSelector={[10, 20, 50, 100]}
                isCreationViaDialog={true}
                filters={filters}
                isEditViaDialog={true}
                setShowCreationDialog={openCreateDialog}
                onSelectedRowChange={(selectedRow) => setSelectedWorkingUnit(selectedRow)}
                loading={isWorkingUnitsLoading || isWorkingUnitCreating || isWorkingUnitUpdating}
            />
            {showCreateWorkingUnitDialog && (
                <WorkingUnitDialog
                    workingUnit={selectedWorkingUnit}
                    taskOptions={tasks}
                    channelOptions={channels}
                    worktypeOptions={worktypes}
                    showDialog={showCreateWorkingUnitDialog}
                    onClose={() => setShowCreateWorkingUnitDialog(false)}
                    onSubmit={handleFormSubmit}
                    isLoading={isWorkingUnitCreating}
                />
            )}
        </>
    );
};

export default WorkingUnits;
