import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Divider, IconButton, Drawer as MuiDrawer, Collapse, Box, List } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Dashboard as DashboardIcon,
    TrendingUp as TrendingUpIcon,
    ShoppingCartOutlined as ShoppingCartOutlinedIcon,
    ViewList as ViewListIcon,
    DashboardOutlined as DashboardOutlinedIcon,
    LocalPhoneOutlined as LocalPhoneOutlinedIcon,
    Event as EventIcon,
    Layers as LayersIcon,
    // Update as UpdateIcon,
    InfoOutlined as InfoOutlinedIcon,
    Public as PublicIcon,
    BarChart as BarChartIcon,
    Settings as SettingsIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Groups as GroupsIcon,
} from '@mui/icons-material';
import UserContext from '../../store/User/UserContext';
import CookieConsent from 'react-cookie-consent';
import VersionNotesImpressumPopover from '../VersionNotesImpressumPopover';
import MenuItem from './MenuItem';

const DRAWER_WIDTH = 240;

const openedMixin = (theme) => ({
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

function SideDrawer({ children, open, setOpen, onSelectedMenuItemChange }) {
    const theme = useTheme();

    const navigate = useNavigate();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = useState(null);

    const [openMenuItems, setOpenMenuItems] = useState({});

    const loggedInUser = useContext(UserContext);
    const loggedInUserRole = useMemo(() => {
        if (!loggedInUser) return null;
        const roles = loggedInUser.role || {};
        if (roles.callmart_osp && roles.callmart_tef) return 'OSP-TEF';
        if (roles.callmart_osp) return 'OSP';
        if (roles.callmart_tef) return 'TEF';

        return null;
    }, [loggedInUser]);

    const menuItems = useMemo(
        () => [
            {
                text: 'Dashboard',
                icon: loggedInUserRole === 'OSP' ? <DashboardIcon /> : <DashboardOutlinedIcon />,
                path: '/dashboard',
                role: '',
                value: 'dashboard',
            },
            {
                text: 'Buchung aktueller Monat',
                icon: <ShoppingCartOutlinedIcon />,
                role: '',
                value: 'allBookings',
                nestedItems: [
                    {
                        text: 'Call Buchung',
                        icon: <ShoppingCartOutlinedIcon />,
                        path: '/ospBookings',
                        role: 'OSP',
                        value: 'ospBookings',
                    },
                    {
                        text: 'Calldeltas',
                        icon: <TrendingUpIcon />,
                        path: '/calldeltaOverview',
                        role: 'TEF',
                        value: 'calldeltas',
                    },
                    {
                        text: 'OSP Buchungen',
                        icon: <ViewListIcon />,
                        path: '/dailyOSPBookings',
                        role: 'TEF',
                        value: 'dailyOSPBookings',
                    },
                ],
            },
            {
                text: 'Meldungen',
                icon: <LocalPhoneOutlinedIcon />,
                role: '',
                value: 'allDeltas',
                nestedItems: [
                    {
                        text: 'Delta Meldung',
                        icon: <LocalPhoneOutlinedIcon />,
                        path: '/locationDeltas',
                        role: '',
                        value: 'locationDeltas',
                    },
                    {
                        text: 'Potenzialmeldung',
                        icon: <LayersIcon />,
                        path: '/capacityReport',
                        role: '',
                        value: 'capacityReport',
                    },
                    {
                        text: 'IST Meldung',
                        icon: <LocalPhoneOutlinedIcon />,
                        path: '/performedCapacityReport',
                        role: '',
                        value: 'performedCapacityReport',
                    },
                    {
                        text: 'Events',
                        icon: <EventIcon />,
                        path: '/events',
                        role: '',
                        value: 'events',
                    },
                ],
            },
            {
                text: 'Bidding',
                icon: <TrendingUpIcon />,
                role: '',
                value: 'bidding',
                nestedItems: [
                    {
                        text: 'Übersicht',
                        path: 'volume',
                        role: 'TEF',
                        value: 'volumeOverview',
                    },
                    {
                        text: 'Strategisch',
                        path: 'volume/strategic',
                        role: '',
                        value: 'strategicVolume',
                    },
                    {
                        text: 'Offered',
                        path: 'volume/offered',
                        role: 'TEF',
                        value: 'offeredVolume',
                    },
                    {
                        text: 'Abschläge',
                        path: '/reduction',
                        role: 'TEF',
                        value: 'reduction',
                    },
                    {
                        text: 'Calculated',
                        path: '/bidding-calculated',
                        role: 'TEF',
                        value: 'biddingCalculated',
                    },
                    {
                        text: 'Vorschlag',
                        path: '/bidding-calculated/totals',
                        role: 'OSP',
                        value: 'calculatedTotals',
                    },
                ],
            },
            {
                text: 'WFM',
                icon: <GroupsIcon />,
                path: '/WFM',
                role: 'TEF',
                value: 'WFM',
            },
            {
                text: 'Capacity Analysis',
                icon: <BarChartIcon />,
                path: '/capacityAnalysis',
                role: 'TEF',
                value: 'capacityAnalysis',
            },
            {
                text: 'Verwaltung',
                role: 'TEF',
                icon: <SettingsIcon />,
                value: 'settings',
                nestedItems: [
                    {
                        text: 'Standorte',
                        icon: <PublicIcon />,
                        path: '/locations',
                        role: 'TEF',
                        value: 'locationSettings',
                    },
                    {
                        text: 'Worktypes',
                        icon: <PublicIcon />,
                        path: '/worktypes',
                        role: 'TEF',
                        value: 'worktypeSettings',
                    },
                    {
                        text: 'Tasks',
                        icon: <PublicIcon />,
                        path: '/tasks',
                        role: 'TEF',
                        value: 'taskSettings',
                    },
                    {
                        text: 'Capacity Types',
                        icon: <PublicIcon />,
                        path: '/capacityTypes',
                        role: 'TEF',
                        value: 'capacityTypeSettings',
                    },
                    {
                        text: 'Channels',
                        icon: <PublicIcon />,
                        path: '/channels',
                        role: 'TEF',
                        value: 'channelSettings',
                    },
                    {
                        text: 'Working Units',
                        icon: <PublicIcon />,
                        path: '/workingUnits',
                        role: 'TEF',
                        value: 'workingUnitSettings',
                    },
                    {
                        text: 'Capacities',
                        icon: <PublicIcon />,
                        path: '/capacities',
                        role: 'TEF',
                        value: 'capacitySettings',
                    },
                    {
                        text: 'Strategic Volumes',
                        icon: <PublicIcon />,
                        path: '/strategic-volumes',
                        role: 'TEF',
                        value: 'strategicVolumeSettings',
                    },
                    {
                        text: 'Öffnungszeiten',
                        icon: <PublicIcon />,
                        path: '/workingHours',
                        role: 'TEF',
                        value: 'workingHoursSettings',
                    },
                ],
            },
            /*** commented out until feature release
            {
                text: '30 min Potentiale',
                icon: <UpdateIcon />,
                path: '/potentials',
                role: '',
            },*/
        ],
        [loggedInUserRole]
    );

    const handleDrawerClose = () => {
        setOpen(false);
        setOpenMenuItems([]);
    };

    const selectMenuItem = (item) => {
        setOpen(true);
        item.nestedItems
            ? setOpenMenuItems((prevState) => ({
                  ...prevState,
                  [item.value]: !prevState[item.value],
              }))
            : navigate(item.path);
    };

    const selectNestedMenuItem = (nestedItem) => {
        setOpen(true);
        if (onSelectedMenuItemChange) {
            onSelectedMenuItemChange(nestedItem.text || nestedItem.value || ''); // kept this for faster response
        }
        navigate(nestedItem.path);
    };

    const findMenuItem = useCallback((menuItems, location) => {
        if (!menuItems || !location) return undefined;
        const normalizedLocation = (location.pathname || '').replace(/^\//, '');
        for (let item of menuItems) {
            const normalizedPath = (item.path || '').replace(/^\//, '');
            if (normalizedLocation === normalizedPath) {
                return item;
            }
            if (item.nestedItems) {
                const nestedItem = findMenuItem(item.nestedItems, location);
                if (nestedItem) return nestedItem;
            }
        }
        return undefined;
    }, []);

    useEffect(() => {
        const currentMenuItem = findMenuItem(menuItems, location);
        if (currentMenuItem && onSelectedMenuItemChange) {
            onSelectedMenuItemChange(currentMenuItem.text || currentMenuItem.value || '');
        }
    }, [findMenuItem, location, menuItems, onSelectedMenuItemChange]);

    const showMenuItemExpandIcon = (item) => {
        if (item?.nestedItems) {
            if (openMenuItems[item.value]) return <ExpandLessIcon />;

            return <ExpandMoreIcon />;
        }

        return null;
    };

    const openVersionNotesImpressumPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeVersionNotesImpressumPopover = () => {
        setAnchorEl(null);
    };

    const isItemAllowedForUser = (allowedRoleForItem, loggedInUserRole) => {
        if (!loggedInUserRole) return false;
        return loggedInUserRole === 'OSP-TEF' || !allowedRoleForItem || allowedRoleForItem === loggedInUserRole;
    };

    return (
        <>
            {loggedInUser?.isCallmartUser && (
                <Drawer variant="permanent" open={open} anchor="left">
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>

                    <Divider />

                    <List>
                        {menuItems.map(
                            (item) =>
                                isItemAllowedForUser(item.role, loggedInUserRole) && (
                                    <Box key={item.value}>
                                        <MenuItem
                                            item={item}
                                            isMenuOpen={open}
                                            showMenuItemExpandIcon={showMenuItemExpandIcon}
                                            onClick={() => selectMenuItem(item)}
                                            currentLocation={location}
                                            isNestedItem={false}
                                            nestedItems={item.nestedItems}
                                        ></MenuItem>
                                        {item?.nestedItems && (
                                            <Collapse in={openMenuItems[item.value]} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {item.nestedItems
                                                        .filter((nestedItem) =>
                                                            isItemAllowedForUser(nestedItem.role, loggedInUserRole)
                                                        )
                                                        .map((nestedItem) => (
                                                            <MenuItem
                                                                key={nestedItem.value}
                                                                item={nestedItem}
                                                                isNestedItem={true}
                                                                isMenuOpen={open}
                                                                showMenuItemExpandIcon={showMenuItemExpandIcon}
                                                                onClick={() => selectNestedMenuItem(nestedItem)}
                                                                currentLocation={location}
                                                            ></MenuItem>
                                                        ))}
                                                </List>
                                            </Collapse>
                                        )}
                                    </Box>
                                )
                        )}
                    </List>
                </Drawer>
            )}
            <main
                style={
                    location.pathname === '/'
                        ? {}
                        : {
                              backgroundColor: theme.palette.background.default,
                              flexGrow: 1,
                              padding: 3,
                              minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight + 2 * theme.spacing(3)}px)`,
                          }
                }
            >
                {children}
                {loggedInUser?.isCallmartUser && (
                    <>
                        <IconButton
                            onClick={openVersionNotesImpressumPopover}
                            aria-label="info"
                            className="action-icon-button"
                            style={{ position: 'fixed', bottom: '10px', right: '10px' }}
                        >
                            <InfoOutlinedIcon fontSize="large" />
                        </IconButton>
                        <VersionNotesImpressumPopover
                            anchorEl={anchorEl}
                            onClose={closeVersionNotesImpressumPopover}
                        ></VersionNotesImpressumPopover>
                    </>
                )}
            </main>
            <CookieConsent
                location="bottom"
                buttonText="Got it"
                cookieName="callmartCookie"
                style={{ background: 'var(--darkGrey)', zIndex: '99999' }}
                buttonStyle={{
                    color: 'var(--black)',
                    background: 'var(--grey)',
                    fontSize: '18px',
                    borderRadius: '5px',
                    padding: '10px',
                }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.{' '}
            </CookieConsent>
        </>
    );
}

export default SideDrawer;
