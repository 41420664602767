import { useRef, useState } from 'react';
import { CheckboxCellRenderer } from '../AgGrid/CheckboxCellRenderer/CheckboxCellRenderer';
import { EventNameCellRenderer } from '../AgGrid/EventNameCellRenderer';
import { formatDateInCell, formatTimeInCell, timeComparator } from '../../utils/agGridUtils';
import ButtonIconRenderer from '../AgGrid/ButtonIconRenderer/ButtonIconRenderer';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { EditButtonCellRenderer } from '../AgGrid/EditButtonCellRenderer/EditButtonCellRenderer';
import { DeleteButtonCellRenderer } from '../AgGrid/DeleteButtonCellRenderer/DeleteButtonCellRenderer';
import EditRecordOrSerieDialog from './EditDeleteRecordOrSerieDialog';
import TableGridItem from '../UI/TableGridItem';

const actionColumnDefs = {
    field: 'id',
    headerName: '',
    filter: false,
    sortable: false,
    suppressHeaderMenuButton: true,
    maxWidth: 55,
    editable: false,
};

const EventReductionTable = ({
    tableRows,
    selectedRow,
    onRowSelect,
    onRowDelete,
    onRowCopy,
    durationTotal,
    reductionTotal,
    getRecordById,
    onRowEditConfirmation,
}) => {
    const gridRef = useRef();
    const [showDeleteRecordOrSerieDialog, setShowDeleteRecordOrSerieDialog] = useState(false);
    const [showEditRecordOrSerieDialog, setShowEditRecordOrSerieDialog] = useState(false);

    const pinnedBottomRowData = [
        {
            pinnedRowTotal: 'Gesamt',
            duration: durationTotal,
            reduction: reductionTotal,
            id: '',
        },
    ];
    const formatReduction = (params) => {
        return params.value?.toFixed(2) + ' %';
    };

    const handleRowDelete = async (params) => {
        onRowSelect(params.data);
        setShowDeleteRecordOrSerieDialog(true);
    };

    const deleteRow = async (deleteSerie = false) => {
        await onRowDelete({ id: selectedRow.id, deleteSerie });
        closeDeleteRowDialog();
    };

    const closeDeleteRowDialog = () => {
        setShowDeleteRecordOrSerieDialog(false);
        onRowSelect(null);
    };

    const handleRowEdit = async (params) => {
        const record = await getRecordById(params.data.id);
        const serieId = record.eventSerieId || record.reductionSerieId || null;
        const serie = record.eventSerie || record.reductionSerie || null;
        onRowSelect({ ...record, serieId, serie });
        if (record.eventSerieId || record.reductionSerieId) setShowEditRecordOrSerieDialog(true);
        else onRowEditConfirmation();
    };

    const closeEditDeleteRecordOrSerieDialog = (isDelete) => {
        onRowSelect(null);
        isDelete ? setShowDeleteRecordOrSerieDialog(false) : setShowEditRecordOrSerieDialog(false);
    };

    const editRow = (editOnlyRecord) => {
        onRowSelect((prevSelectedRow) => ({ ...prevSelectedRow, editOnlyRecord }));
        if (showEditRecordOrSerieDialog) setShowEditRecordOrSerieDialog(false);
        onRowEditConfirmation();
    };

    const copySerie = (serie) => {
        return serie ? { ...serie, id: null } : null;
    };

    const handleRowCopy = async (params) => {
        const record = await getRecordById(params.data.id);
        const { eventSerie, reductionSerie } = record;

        onRowSelect({
            ...record,
            id: null,
            copyRecord: true,
            serieId: null,
            serie: copySerie(eventSerie) || copySerie(reductionSerie),
            eventSerie: copySerie(eventSerie),
            reductionSerie: copySerie(reductionSerie),
        });

        onRowCopy();
    };
    const columnDefs = [
        {
            field: 'location',
            headerName: 'Stadt',
            valueGetter: (params) =>
                params.data.pinnedRowTotal || params?.data?.location?.code + ' - ' + params?.data?.location?.city,
        },
        {
            field: 'worktypes',
            headerName: 'Worktype',
            minWidth: 115,
            valueGetter: (params) =>
                params?.data?.workingUnitLocations?.map((wuLoc) => wuLoc?.workingUnit.worktype.worktype).join(','),
        },
        {
            field: 'channel.name',
            headerName: 'Kanal',
            valueGetter: (params) =>
                params?.data?.workingUnitLocations?.map((wuLoc) => wuLoc?.workingUnit?.channel?.name).join(','),
        },
        {
            field: 'task.name',
            headerName: 'Task',
            valueGetter: (params) =>
                params?.data?.workingUnitLocations?.map((wuLoc) => wuLoc?.workingUnit?.task?.name).join(','),
        },
        {
            field: 'name',
            headerName: 'Name',
            cellRenderer: EventNameCellRenderer,
        },
        {
            field: 'comment',
            headerName: 'Kommentar',
            minWidth: 125,
        },
        {
            field: 'start',
            headerName: 'Datum von',
            valueFormatter: formatDateInCell,
            minWidth: 100,
        },
        {
            field: 'end',
            headerName: 'Datum bis',
            valueFormatter: formatDateInCell,
            minWidth: 100,
        },
        {
            field: 'start',
            headerName: 'Uhrzeit von',
            comparator: timeComparator,
            valueFormatter: formatTimeInCell,
            minWidth: 115,
        },
        {
            field: 'end',
            headerName: 'Uhrzeit bis',
            comparator: timeComparator,
            valueFormatter: formatTimeInCell,
            minWidth: 115,
        },
        {
            field: 'duration',
            headerName: 'Dauer',
        },
        {
            field: 'reduction',
            headerName: 'Abschlag %',
            valueFormatter: (params) => formatReduction(params),
            minWidth: 115,
        },
        {
            field: 'increase',
            headerName: 'Aufschlag',
            minWidth: 115,
            cellRenderer: CheckboxCellRenderer,
            cellRendererParams: {
                propertyName: 'increase',
            },
        },
        {
            field: 'productive',
            headerName: 'Produktiv',
            minWidth: 115,
            cellRenderer: CheckboxCellRenderer,
            cellRendererParams: {
                propertyName: 'productive',
            },
        },
        {
            ...actionColumnDefs,
            colId: 'edit',
            cellRenderer: ButtonIconRenderer,
            cellRendererParams: {
                icon: ContentCopyIcon,
                onClick: handleRowCopy,
            },
        },
        {
            ...actionColumnDefs,
            colId: 'edit',
            cellRenderer: EditButtonCellRenderer,
            cellRendererParams: {
                clicked: handleRowEdit,
            },
        },
        {
            ...actionColumnDefs,
            colId: 'delete',
            cellRenderer: DeleteButtonCellRenderer,
            cellRendererParams: {
                clicked: handleRowDelete,
            },
        },
    ];

    const styleRow = (params) => {
        if (params.node.rowPinned) {
            return { fontWeight: 'bold' };
        }
    };

    return (
        <>
            <TableGridItem
                ref={gridRef}
                rowData={tableRows}
                defaultColDef={{
                    editable: false,
                    filter: true,
                    sortable: true,
                    minWidth: 90,
                }}
                columnDefs={columnDefs}
                pinnedBottomRowData={pinnedBottomRowData}
                getRowStyle={styleRow}
            ></TableGridItem>
            {showDeleteRecordOrSerieDialog && selectedRow && (
                <EditRecordOrSerieDialog
                    showDialog={showDeleteRecordOrSerieDialog}
                    onClose={() => closeEditDeleteRecordOrSerieDialog(true)}
                    onConfirm={deleteRow}
                    isDelete={true}
                    recordName={selectedRow?.name}
                    serieId={selectedRow?.reductionSerieId || selectedRow?.eventSerieId || null}
                ></EditRecordOrSerieDialog>
            )}
            {showEditRecordOrSerieDialog && selectedRow && (
                <EditRecordOrSerieDialog
                    showDialog={showEditRecordOrSerieDialog}
                    onClose={() => closeEditDeleteRecordOrSerieDialog(false)}
                    onConfirm={editRow}
                    isDelete={false}
                ></EditRecordOrSerieDialog>
            )}
        </>
    );
};

export default EventReductionTable;
