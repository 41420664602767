import { useCallback, useState } from 'react';
import CRUDSettings from '../components/General/CRUDSettings';
import {
    useAlertMessage,
    useAllHEMTLocationsWithWorktypes,
    useCapacities,
    useCapacityTypes,
    useChannels,
    useCreateCapacity,
    useTasks,
    useUpdateCapacity,
    useWorktypeLocations,
} from '../hooks';
import CreateCapacityDialog from '../components/Capacities/CreateCapacityDialog';
import { getCellValue } from '../utils/agGridUtils';

const Capacities = () => {
    const { dispatchAlert } = useAlertMessage();

    const [locationsWithWorktypes] = useAllHEMTLocationsWithWorktypes();
    const [worktypeLocations] = useWorktypeLocations();
    const [capacities, isCapacitiesLoading] = useCapacities();
    const [channels] = useChannels();
    const [tasks] = useTasks();
    const [capacityTypes] = useCapacityTypes();

    const [showCreateCapacityDialog, setShowCreateCapacityDialog] = useState(false);

    const { mutate: handleCreateCapacity, isLoading: isCreateCapacityLoading } = useCreateCapacity({
        onSettled: () => {
            setShowCreateCapacityDialog(false);
        },
    });
    const { mutate: handleUpdateCapacity } = useUpdateCapacity({
        onSettled: () => {
            setShowCreateCapacityDialog(false);
        },
    });

    const validateData = useCallback(
        (data) => {
            const { location, workingUnitLocation } = data;
            const matchingWorktypeLocation = worktypeLocations.find(
                (wtLoc) =>
                    wtLoc.locationId === location.id && wtLoc.worktypeId === workingUnitLocation.workingUnit.worktypeId
            );

            if (matchingWorktypeLocation) return true;

            return dispatchAlert({
                message: `Please select valid worktype for the selected location`,
                type: 'error',
            });
        },
        [dispatchAlert, worktypeLocations]
    );

    const getWorktypeValues = (params) => {
        const selectedLocation = params.data.location;
        return locationsWithWorktypes.find((loc) => loc.id === selectedLocation.id).worktypes;
    };

    const getCommonColDefs = (values, colName, secondColName) => {
        return {
            editable: (params) => params.data.isRowEdited || params.data.isNewRecord,
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: (params) => ({
                values: Array.isArray(values) ? values : values(params),
                cellRenderer: (params) =>
                    typeof params.value === 'string' ? params.value : getCellValue(params, colName, secondColName),
            }),
            cellRenderer: (params) => getCellValue(params, colName, secondColName),
        };
    };

    const columnDefs = [
        {
            field: 'location',
            headerName: 'Standort',
            ...getCommonColDefs(locationsWithWorktypes, 'city', 'code'),
            valueGetter: ({ data }) => (data?.location ? `${data.location.code} - ${data.location.city}` : ''),
            cellRenderer: ({ value }) => value,
            filterParams: {
                valueGetter: ({ data }) => (data?.location ? `${data.location.code} - ${data.location.city}` : ''),
            },
        },
        {
            field: 'workingUnitLocation.workingUnit.worktype',
            headerName: 'Worktype',
            ...getCommonColDefs(getWorktypeValues, 'worktype'),
            filterParams: {
                valueGetter: ({ data }) => data?.workingUnitLocation?.workingUnit.worktype.worktype,
            },
            valueFormatter: ({ value }) => value?.worktype,
        },
        {
            field: 'workingUnitLocation.workingUnit.channel',
            headerName: 'Kanal',
            ...getCommonColDefs(channels, 'name'),
            filterParams: {
                valueGetter: (params) => {
                    return params.data?.workingUnitLocation?.channel.name;
                },
            },
            valueFormatter: ({ value }) => value?.name,
        },
        {
            field: 'workingUnitLocation.workingUnit.task',
            headerName: 'Task',
            ...getCommonColDefs(tasks, 'name'),
            filterParams: {
                valueGetter: (params) => {
                    return params.data?.workingUnitLocation?.task.name;
                },
            },
            valueFormatter: ({ value }) => value?.name,
        },
        {
            field: 'capacityType',
            headerName: 'Capacity Type',
            ...getCommonColDefs(capacityTypes, 'name'),
            filterParams: {
                valueGetter: (params) => {
                    return params.data?.capacityType.name;
                },
            },
            valueFormatter: ({ value }) => value?.name,
        },
    ];

    return (
        <>
            <CRUDSettings
                tableRows={capacities}
                columnDefs={columnDefs}
                columnToFocusOnEdit="location"
                btnText="Neuer Capacity"
                recordType="Capacity"
                updateRecord={handleUpdateCapacity}
                recordStructure={{
                    location: null,
                    worktypeLocation: { worktype: null },
                    channel: null,
                    task: null,
                    capacityType: null,
                }}
                validateData={validateData}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={[10, 20, 50, 100]}
                isCreationViaDialog={true}
                setShowCreationDialog={setShowCreateCapacityDialog}
                loading={isCapacitiesLoading}
            ></CRUDSettings>
            {showCreateCapacityDialog && (
                <CreateCapacityDialog
                    taskOptions={tasks}
                    channelOptions={channels}
                    capacityTypeOptions={capacityTypes}
                    showDialog={showCreateCapacityDialog}
                    onClose={() => setShowCreateCapacityDialog(false)}
                    onSubmit={handleCreateCapacity}
                    isLoading={isCreateCapacityLoading}
                ></CreateCapacityDialog>
            )}
        </>
    );
};

export default Capacities;
