import { useState, useContext } from 'react';
import { Grid, InputAdornment } from '@mui/material';
import { useForm } from 'react-hook-form';
import { fieldIsRequiredMsg } from '../../utils/componentUtils';
import ReactHookFormTextField from '../ReactHookForm/ReactHookFormTextField';
import ReactHookFormSelect from '../ReactHookForm/ReactHookFormSelect';
import dayjs from 'dayjs';
import { useWorkingUnitLocations } from '../../hooks';
import PercentIcon from '@mui/icons-material/Percent';
import ReactHookFormCheckbox from '../ReactHookForm/ReactHookFormCheckbox';
import ReactHookFormAutoComplete from '../ReactHookForm/ReactHookFormAutoComplete';
import ReactHookFormDateTimePicker from '../ReactHookForm/ReactHookFormDateTimePicker';
import { isEndAfterStart, getDuration } from '../../utils/dateUtils';
import { RecurringOptions, getRepetitionIntervalType } from './utils/serieDialogUtils';
import EventReductionSerieDialog from './EventReductionSerieDialog';
import EventRecurringInfo from './EventRecurringInfo';
import UserContext from '../../store/User/UserContext';
import FormDialog from '../Dialogs/FormDialog';

const EventReductionDialog = ({
    showDialog,
    onShowDialog,
    record,
    location,
    locationOptions,
    onRowSelect,
    postRecord,
    dialogTitle,
    isLoading,
}) => {
    const loggedInUser = useContext(UserContext);

    const [showSerieDialog, setShowSerieDialog] = useState(false);

    const form = useForm({
        defaultValues: {
            id: record?.id || null,
            name: record?.name || '',
            reduction: record?.reduction || '',
            start: record ? dayjs(record.start) : dayjs(),
            end: record ? dayjs(record.end) : dayjs(),
            location:
                record && record?.workingUnitLocations
                    ? record.workingUnitLocations[0].worktypeLocation.locationId
                    : location
                    ? location
                    : '',
            user: loggedInUser?.id || null,
            workingUnitLocations:
                record && record.workingUnitLocations
                    ? record.workingUnitLocations.map((wuLoc) => ({
                          ...wuLoc,
                          label: `${wuLoc.workingUnit.worktype.worktype} - ${wuLoc.workingUnit.channel?.name} - ${wuLoc.workingUnit.task?.name}`,
                      }))
                    : [],
            comment: record?.comment || '',
            productive: record?.productive || false,
            increase: record?.increase || false,
            recurringOption: record?.serie?.frequency || RecurringOptions[0].value,
            serie: record?.serie
                ? { ...record.serie, repetitionIntervalType: getRepetitionIntervalType(record.serie) }
                : null,
            serieId: record?.serieId || null,
            editOnlyRecord: record?.editOnlyRecord,
        },
    });
    const {
        watch,
        formState: { errors },
        control,
        setValue,
        resetField,
        getValues,
    } = form;

    const selectedStart = watch('start');
    const selectedRecurringOption = watch('recurringOption');
    const selectedLocation = watch('location');
    const [workingUnitLocations] = useWorkingUnitLocations({ location: selectedLocation });
    const workingUnitOptions = workingUnitLocations.map((wuLoc) => ({
        ...wuLoc,
        label: `${wuLoc.workingUnit.worktype.worktype} - ${wuLoc.workingUnit.channel?.name} - ${wuLoc.workingUnit.task?.name}`,
    }));

    const showSerieInfo = record?.serie && selectedRecurringOption !== RecurringOptions[0].value;

    const getRecordForSerieDialog = () => {
        const recurringOption = getValues('recurringOption');
        const start = getValues('start');
        const end = getValues('end');

        return {
            ...(record || {}),
            start,
            end,
            recurringOption,
        };
    };

    const submitEventRecurringDialog = (values) => {
        setValue('recurringOption', values.frequency);
        setValue('serie', values);

        const duration = getDuration(selectedStart, getValues('end'));
        const eventStart = values.start;
        const eventEnd = values.start.add(duration);
        setValue('start', eventStart);
        setValue('end', eventEnd);

        onRowSelect((prevValues) => ({
            ...(prevValues || {}),
            start: eventStart,
            end: eventEnd,
            serie: values,
        }));
        setShowSerieDialog(false);
    };

    const onSubmit = async (values) => {
        if (values.serie) {
            values.serie.frequency = selectedRecurringOption;
        }
        postRecord(values);
    };

    const handleClose = () => {
        onRowSelect(null);
        onShowDialog(false);
    };

    const handleSerieDialogClose = () => {
        setShowSerieDialog(false);
        resetField('recurringOption');
    };

    const onRecurringOptionClick = () => {
        if (!record?.editOnlyRecord && ['week', 'month', 'year'].includes(getValues('recurringOption'))) {
            setShowSerieDialog(true);
        } else {
            setShowSerieDialog(false);
        }
    };

    const handleLocationChange = () => {
        setValue('workingUnitLocations', []);
    };

    return (
        <>
            <FormDialog
                id="eventReductionDialog"
                title={dialogTitle}
                showDialog={showDialog}
                form={form}
                onSubmit={onSubmit}
                onClose={handleClose}
                isLoading={isLoading}
            >
                <Grid item xs={12}>
                    <ReactHookFormTextField
                        name="name"
                        control={control}
                        label="Name"
                        formErrors={errors}
                    ></ReactHookFormTextField>
                </Grid>
                {locationOptions && (
                    <Grid item xs={12}>
                        <ReactHookFormSelect
                            name="location"
                            control={control}
                            label="Standort *"
                            options={locationOptions}
                            mainProp="code"
                            secondProp="city"
                            rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                            formErrors={errors}
                            onItemClick={handleLocationChange}
                        ></ReactHookFormSelect>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <ReactHookFormAutoComplete
                        name="workingUnitLocations"
                        control={control}
                        label="Working Unit *"
                        labelKeys={['label']}
                        options={workingUnitOptions}
                        multiple
                        rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                        formErrors={errors}
                    ></ReactHookFormAutoComplete>
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextField
                        name="reduction"
                        control={control}
                        label="Aufschlag/Abschlag *"
                        isNumeric={true}
                        inputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <PercentIcon edge="end" />
                                </InputAdornment>
                            ),
                        }}
                        rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                        formErrors={errors}
                    ></ReactHookFormTextField>
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox
                        name="increase"
                        control={control}
                        label="Aufschlag"
                        labelPlacement="end"
                    ></ReactHookFormCheckbox>
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormDateTimePicker
                        name="start"
                        control={control}
                        label="Start"
                        rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                        formErrors={errors}
                    ></ReactHookFormDateTimePicker>
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormDateTimePicker
                        name="end"
                        control={control}
                        label="End"
                        rules={{
                            required: { value: true, message: fieldIsRequiredMsg },
                            validate: (value) => isEndAfterStart(selectedStart, value),
                        }}
                        minDate={selectedStart}
                        formErrors={errors}
                    ></ReactHookFormDateTimePicker>
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextField
                        name="comment"
                        control={control}
                        label="Kommentar"
                        isMultiline={true}
                        rows={4}
                    ></ReactHookFormTextField>
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormSelect
                        name="recurringOption"
                        control={control}
                        label=""
                        mainProp="text"
                        valueProp="value"
                        options={RecurringOptions}
                        formErrors={errors}
                        disabled={record?.editOnlyRecord}
                        onItemClick={onRecurringOptionClick}
                    ></ReactHookFormSelect>
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormCheckbox
                        name="productive"
                        control={control}
                        label="Produktiv"
                        labelPlacement="end"
                    ></ReactHookFormCheckbox>
                </Grid>
                {showSerieInfo && <EventRecurringInfo eventSerie={record.serie} />}
            </FormDialog>
            {showSerieDialog && (
                <EventReductionSerieDialog
                    showDialog={showSerieDialog}
                    onClose={handleSerieDialogClose}
                    onSubmit={submitEventRecurringDialog}
                    record={getRecordForSerieDialog()}
                ></EventReductionSerieDialog>
            )}
        </>
    );
};

export default EventReductionDialog;
