import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../enums/QueryKey';
import { getUserById } from '../../services/telefonica';

export function useIsUserAllowedToAccessALocation(userId, locationId) {
    const { data: user } = useQuery({
        queryKey: [QueryKey.User, { id: userId }],
        queryFn: () => getUserById(userId),
        enabled: !!userId && !!locationId,
    });

    const userLocationIds = user ? [user.primaryLocationId].concat(user.additionalLocationIds) : [];
    return userLocationIds.includes(locationId);
}
