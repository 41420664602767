import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

function MenuItem({
    item,
    isNestedItem,
    nestedItems,
    isMenuOpen,
    showMenuItemExpandIcon,
    onClick,
    currentLocation,
    ...props
}) {
    const normalizedLocation = (currentLocation?.pathname || '').replace(/^\//, '') || 'dashboard';
    const normalizedPath = (item?.path || '').replace(/^\//, '');
    const isSelected = normalizedLocation === normalizedPath;
    const isNestedItemSelected = nestedItems?.some(
        (nestedItem) => normalizedLocation === nestedItem.path.replace(/^\//, '')
    );

    return (
        <ListItemButton
            onClick={onClick}
            component={!item?.nestedItems ? Link : 'div'}
            selected={isSelected || isNestedItemSelected}
            {...(!item?.nestedItems && { to: item.path })}
            {...props}
        >
            <ListItemIcon>{(isMenuOpen && !isNestedItem) || !isMenuOpen ? item.icon : null}</ListItemIcon>
            <ListItemText
                primary={item.text}
                primaryTypographyProps={isMenuOpen ? { whiteSpace: 'normal' } : { whiteSpace: 'nowrap' }}
            />
            {showMenuItemExpandIcon(item)}
        </ListItemButton>
    );
}

export default MenuItem;
