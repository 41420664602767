import { useMutation, useQuery } from '@tanstack/react-query';
import { createWorkingUnit, getWorkingUnits, updateWorkingUnit } from '../../services/workingUnit';
import { QueryKey } from '../../enums/QueryKey';
import { useDispatchErrorNotification } from '../useDispatchErrorNotification';
import { useDispatchSuccessfullySavedRecord } from '../useDispatchSuccessfullySavedRecord';
import { queryClient } from '../../services/queryClient';
import { useUpdateRecord } from './crudOperations';

export function useWorkingUnits({ location, hasMatchingOfferedCluster = false, prependAllOption = false } = {}) {
    const {
        data: workingUnits,
        isFetchedAfterMount,
        isFetching,
        isLoading,
    } = useQuery({
        queryKey: [QueryKey.WorkingUnits, { ...(location && { location }), hasMatchingOfferedCluster }],
        queryFn: () => getWorkingUnits({ location, hasMatchingOfferedCluster }),
        initialData: [],
    });

    const loading = isFetchedAfterMount ? isLoading : isFetching;
    if (workingUnits.length && prependAllOption) {
        return [
            [
                {
                    id: -1,
                    worktype: {
                        worktype: 'Alle Working Units',
                    },
                    channel: {
                        name: '',
                    },
                    task: {
                        name: '',
                    },
                },
                ...workingUnits,
            ],
            loading,
        ];
    }

    return [workingUnits, loading];
}

export function useCreateWorkingUnit({ onSettled }) {
    const { dispatchErrorNotification } = useDispatchErrorNotification();
    const { dispatchSuccessfullySavedRecord } = useDispatchSuccessfullySavedRecord();

    return useMutation({
        mutationFn: createWorkingUnit,
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.WorkingUnits]);
            dispatchSuccessfullySavedRecord('Working Unit');
        },
        onError: (error) => {
            dispatchErrorNotification({ message: error.message });
        },
        onSettled,
    });
}

export function useUpdateWorkingUnit({ onSettled }) {
    return useUpdateRecord({
        updateRecordFn: updateWorkingUnit,
        fetchRecordsQueryKey: QueryKey.WorkingUnits,
        recordName: 'Working Unit',
        onSettled,
    });
}
