import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
    Routes,
    Route,
    BrowserRouter,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from 'react-router-dom';
import axios from 'axios';
import { Auth0ProviderWithHistory } from './components/Auth/Auth0ProviderWithHistory';
import { LicenseManager } from 'ag-grid-enterprise';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './services/queryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import ErrorFallback from './pages/ErrorFallback';
import { sentryDsn, isDevelopment, envName, apiURL, agGridLicense } from './config';

Sentry.init({
    dsn: sentryDsn,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 10% of transactions for performance monitoring.
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: !isDevelopment,
    environment: envName,
});
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

LicenseManager.setLicenseKey(agGridLicense);

axios.defaults.baseURL = apiURL;

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <SentryRoutes>
                    <Route
                        path="*"
                        element={
                            <Sentry.ErrorBoundary fallback={ErrorFallback}>
                                <Auth0ProviderWithHistory>
                                    <App />
                                </Auth0ProviderWithHistory>
                            </Sentry.ErrorBoundary>
                        }
                    />
                </SentryRoutes>
            </BrowserRouter>
            {isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
    </React.StrictMode>
);
